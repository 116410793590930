import { connect } from 'react-redux'
import Moderation from './Moderation'
import { fetchUser } from '../../actions/userActions'
import { fetchPosts } from '../../actions/postsActions'

export const mapStateToProps = ({ users, posts }) => {
	return {
		user: users.user,
		posts: posts.posts,
		isLoading: posts.isLoading || users.isLoading,
		isUpdating: posts.isUpdating,
		post: posts.post || {},
	}
}

export const mapDispatchToProps = { fetchPosts, fetchUser }

export default connect(mapStateToProps, mapDispatchToProps)(Moderation)
