import { connect } from 'react-redux'
import CareCard from './careCard'
import { approvePost, hidePost, updatePost } from '../../actions/postsActions'
import { openModalDialog } from '../../actions/layoutActions'

export const mapStateToProps = ({ posts }) => {
	return {
		isUpdating: posts.isUpdating,
	}
}

export const mapDispatchToProps = { approvePost, hidePost, updatePost, openModalDialog }

export default connect(mapStateToProps, mapDispatchToProps)(CareCard)
