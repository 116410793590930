import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Container, Row } from 'reactstrap'
import { navigate } from 'gatsby'

import Layout from '../../components/layout'
import CareCard from '../../components/CareCard'
import SEO from '../../components/seo'
import Spinner from '../../components/Spinner'

const Moderation = ({ fetchUser, fetchPosts, posts = [], user, isLoading }) => {
	useEffect(() => {
		const userId = window.localStorage.getItem('userId')
		fetchUser(userId)
	}, [fetchUser])

	useEffect(() => {
		if (user) {
			if (user.is_moderator) {
				fetchPosts({ is_reviewed: 0 })
			} else {
				navigate('/')
			}
		}
	}, [user, fetchPosts])

	function renderPosts() {
		return (
			<Row className='p-3 justify-content-center'>
				{posts && posts.length ? (
					posts.map(post => {
						return (
							<Row key={post.id}>
								<div className='px-2'>
									<CareCard key={post.id} post={post} showModerationOptions />
								</div>
							</Row>
						)
					})
				) : (
					<Row className='hashtag-no-results px-4 pb-3'>No more cards to review!</Row>
				)}
			</Row>
		)
	}

	return (
		<Layout>
			<SEO title='Admin' />
			<Container>
				{isLoading ? (
					<Row className='justify-content-center hashtag-no-results px-4 pb-3'>
						<Spinner />
					</Row>
				) : (
					renderPosts()
				)}
			</Container>
		</Layout>
	)
}

Moderation.propTypes = {
	fetchUser: PropTypes.func,
	fetchPosts: PropTypes.func,
	posts: PropTypes.arrayOf(PropTypes.object),
	user: PropTypes.object,
	isLoading: PropTypes.bool,
}

export default Moderation
